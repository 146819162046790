/*
 * @Description: api静态变量
 * @Author: laojunpeng
 * @Date: 2022-09-28 15:47:03
 * @LastEditTime: 2023-03-30 10:30:31
 * @LastEditors: laojunpeng
 * @FilePath: /src/CodiggerWeb/enums/http.js
 *
 * This software is the confidential and proprietary information of
 * OneCloud Co., Ltd. ('Confidential Information'). You shall not
 * disclose such Confidential Information and shall use it only in
 * accordance with the terms of the license agreement you entered
 * into with OneCloud.
 */


export const HTTP_SUCCESS_CODE = 200;
export const HTTP_RESPONSE_SUCCESS_CODES = [200, 0];


export const HTTP_ERROR_CODE = {
  // 登录后获取的cookie过期
  'LOGIN_COOKIE_EXPIRE':60101,
  'LOGIN_COOKIE_EXPIRE2':60208,
  'LOGIN_COOKIE_EXPIRE3':60102
};

export const ContentTypeEnum = {
  // json
  'JSON' : 'application/json;charset=UTF-8',
  // form-data qs
  'FORM_URLENCODED' : 'application/x-www-form-urlencoded;charset=UTF-8',
  // form-data  upload
  'FORM_DATA' : 'multipart/form-data;charset=UTF-8'
};
