/*
 * @Author: laibin
 * @email: laisan8848@163.com
 * @Date: 2024-07-22 10:20:55
 * @LastEditors: laibin
 * @LastEditTime: 2024-07-25 14:16:27
 * @Description: file content
 */
import Template from './template'
import {searchModule, searchModuleAbility} from '../../api'
import * as i18n from '../../i18n';

export async function handleExtenedUrl(path) {
  let params = path.split('/').filter(ele=> ele !== '')
  if(params[0].indexOf('web-') === -1) {
    params = ['web-app', ...params]
  }
  if(params.length < 3) {
    renderTemplate({
      title: 'Error',
      msg: i18n.t('extenedUrl.invalidUrl')
    })
  }
  const appType = params[0].replace('web-', '')
  const appModule = `${appType}-${params[1]}-${params[2]}`
  const {moduleVersion} = await searchModule({'moduleName': appModule})
  let url = ''
  let ability = {}
  if(moduleVersion) {
    const res = await searchModuleAbility({module: appModule, version: moduleVersion})
    const resParse = JSON.parse(res)
    if(resParse) {
      ability = resParse.display
    }
    if(ability.standalone == "false") {
      renderTemplate({
        title: 'Error',
        msg: i18n.t('extenedUrl.standaloneError')
      })
    }
    if(appType == 'app') {
      url = `/${params[1]}/${params[2]}.sys/${moduleVersion}/`
    } else {
      url = `/${params[1]}/${params[2]}.${appType}/${moduleVersion}/`
    }
  } else {
    renderTemplate({
      title: 'Error',
      msg: i18n.t('extenedUrl.invalidUrl')
    })
  }
  if(params[3]) {
    if(ability['extended-url'] && ability['extended-url'].includes(params[3])) {
      url += `#${params[3]}/${params[4] || ''}`
    } else {
      url = ''
      renderTemplate({
        title: 'Function not found',
        msg: i18n.t('extenedUrl.functionError')
      })
    }
  }
  return url
}

function renderTemplate({title, msg}) {
  document.body.append(<Template msg={msg} title={title}/>)
  return
}