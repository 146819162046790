import { createUuid } from "../utils/index";
import {WEBSOCKET_CODE} from './message';
import {Buffer} from 'buffer';
export class DtermSocket
{
  constructor ()
  {
    this.status = 'start';
    this.instance = null;
    this.promise = null;
    this.ack = 0;
    this.callbacks = [];
  }
  initSocket ({username, projectId, url})
  {
    const loginErrorOffline = 0x01;
    const loginErrorBusy = 0x02;
    return new Promise((resolve, reject) =>
    {
      if(!username || !projectId)
      {
        reject('DtermSocket connect require userInfo & projectId');
      }
      this.instance = this.createSocket({username, projectId, url});
      this.instance.addEventListener('message', (e) =>
      {
        const {data} = e;
        if (typeof data === 'string')
        {
          const msg = JSON.parse(data);
          if (msg.type === 'login')
          {
            if (msg.err === loginErrorOffline)
            {
              this.dispatchData({'code':1});
              reject();
              return;
            }
            else if (msg.err === loginErrorBusy)
            {
              this.dispatchData({'code':2});
              reject();
              return;
            }
            this.status = 'ready';
            resolve(this.instance);
          }
        }
      });
      this.initListener(projectId);
    })
  }

  createSocket ({username, projectId, url})
  {
    const connectionId = projectId || createUuid();
    const vimSign = `${username}:${connectionId}`;
    const baseUrl = url;
    const websocketUrl = `${baseUrl}?VimSig=${vimSign}`;
    this.instance = new WebSocket(websocketUrl, 'vim');
    this.instance.binaryType = 'arraybuffer';
    return this.instance;
  }


  initListener (projectId)
  {
    this.instance.addEventListener('close', (data) =>
    {
      this.dispatchData(projectId, {'code':3});
    });
    this.instance.addEventListener('error', () =>
    {
      this.dispatchData(projectId, {'code':4});
    });
    this.instance.addEventListener('message', (ev) =>
    {
      let data = ev.data;
      if(typeof data !== 'string')
      {
        data = Buffer.from(ev.data);
        this.ack = this.ack + data.length;
        const ackBlkSize = 4 * 1024;
        if (this.ack > ackBlkSize)
        {
          const msg = {'type': 'ack', 'ack': this.ack};
          this.send(JSON.stringify(msg));
          this.ack = 0;
        }
        if (typeof data === 'string')
        {
          this.dispatchData({'code':0, data});
        }
        else
        {
          const message = new Uint8Array(data);
          let str = String.fromCharCode.apply(null, message);
          if (!/\slogin:/.test(str) && !/Password:/.test(str) && !/^(Last\slogin).*(on)/.test(str))
          {
            this.dispatchData({'code':0, 'data':message});
          }
        }

      }
    });
  }

  sendDtermData (data)
  {
    const str = Buffer.concat([Buffer.from([0]), Buffer.from(data)]);
    this.send(str);
  }

  sendDtermBinary (data)
  {
    const str = Buffer.concat([Buffer.from([0]), Buffer.from(data, 'binary')]);
    this.send(str);
  }

  send (data)
  {
    if(this.instance)
    {
      this.instance.send(data);
    }
    else
    {
      console.error('send', 'socket is undefinded');
    }
  }

  dispatchData (data)
  {
    if(this.callbacks.length > 0 )
    {
      this.callbacks.forEach((fn) =>
      {
        try
        {
          fn(data);
        }
        catch (error)
        {
          console.error(error);
        }
      });
    }
  }

  setListener (callback)
  {
    this.callbacks.push(callback);
  }

  closeSocket ()
  {
    if(!this.instance)
    {
      return;
    }
    this.instance.close();
  }

  isSocketInit ()
  {
    return Boolean(this.status === 'ready');
  }
}

export default DtermSocket;
