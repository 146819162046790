
const methodMap = [
    [
      'requestFullscreen',
      'exitFullscreen',
      'fullscreenElement',
      'fullscreenEnabled',
      'fullscreenchange',
      'fullscreenerror'
    ],
    // New WebKit
    [
      'webkitRequestFullscreen',
      'webkitExitFullscreen',
      'webkitFullscreenElement',
      'webkitFullscreenEnabled',
      'webkitfullscreenchange',
      'webkitfullscreenerror'
  
    ],
    // Old WebKit
    [
      'webkitRequestFullScreen',
      'webkitCancelFullScreen',
      'webkitCurrentFullScreenElement',
      'webkitCancelFullScreen',
      'webkitfullscreenchange',
      'webkitfullscreenerror'
  
    ],
    [
      'mozRequestFullScreen',
      'mozCancelFullScreen',
      'mozFullScreenElement',
      'mozFullScreenEnabled',
      'mozfullscreenchange',
      'mozfullscreenerror'
    ],
    [
      'msRequestFullscreen',
      'msExitFullscreen',
      'msFullscreenElement',
      'msFullscreenEnabled',
      'MSFullscreenChange',
      'MSFullscreenError'
    ]
  ];
  
  const nativeAPI = (() =>
  {
    if (typeof document === 'undefined')
    {
      return false;
    }
  
    const unprefixedMethods = methodMap[0];
    const returnValue = {};
  
    for (const methodList of methodMap)
    {
      const exitFullscreenMethod = methodList?.[1];
      if (exitFullscreenMethod in document)
      {
        for (const [index, method] of methodList.entries())
        {
          returnValue[unprefixedMethods[index]] = method;
        }
        return returnValue;
      }
    }
  
    return false;
  })();
  
  const eventNameMap = {
    'change': nativeAPI.fullscreenchange,
    'error': nativeAPI.fullscreenerror
  };
  
  const getIsScreenFull = () =>
  {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('Mac OS') > -1)
    {
      return !!document[nativeAPI.fullscreenElement] || (window.innerWidth === screen.width && (window.innerHeight === screen.height || window.outerHeight === screen.height || (window.innerHeight >= window.outerHeight && window.outerHeight > 0)));
    }
    else
    {
      return window.innerHeight === screen.height && window.innerWidth === screen.width;
    }
  };
  

  let index = {
    // eslint-disable-next-line default-param-last
    request (element = document.documentElement, options)
    {
      return new Promise((resolve, reject) =>
      {
        const onFullScreenEntered = () =>
        {
          // index.off('change', onFullScreenEntered);
          resolve();
        };
  
        // index.on('change', onFullScreenEntered);
  
        const returnPromise = element[nativeAPI.requestFullscreen](options);
  
        if (returnPromise instanceof Promise)
        {
          returnPromise.then(onFullScreenEntered).catch(reject);
        }
      });
    },
    exit ()
    {
      return new Promise((resolve, reject) =>
      {
        // if (!index.isFullscreen)
        // {
        //   reject();
        //   return;
        // }
  
        const onFullScreenExit = () =>
        {
          // index.off('change', onFullScreenExit);
          resolve();
        };
  
        // index.on('change', onFullScreenExit);
  
        const returnPromise = document[nativeAPI.exitFullscreen]();
  
        if (returnPromise instanceof Promise)
        {
          returnPromise.then(onFullScreenExit).catch(reject);
        }
      });
    },
    toggle (element, options)
    {
      return index.isFullscreen ? index.exit() : index.request(element, options);
    },
    onchange (callback, immediate)
    {
      // index.on('change', callback);
      if(immediate)
      {
        if (callback)
        {
          callback(getIsScreenFull());
        }
      }
      window.onresize = () =>
      {
        // if(getIsScreenFull())
        // {
        //   index.request();
        // }
        // else
        // {
        //   index.exit();
        // }
        if (callback)
        {
          callback(getIsScreenFull());
        }
      };
    },
    onerror (callback)
    {
      index.on('error', callback);
    },
    on (event, callback)
    {
      const eventName = eventNameMap[event];
      if (eventName)
      {
        document.addEventListener(eventName, callback, false);
      }
    },
    off (event, callback)
    {
      const eventName = eventNameMap[event];
      if (eventName)
      {
        document.removeEventListener(eventName, callback, false);
      }
    },
    'raw': nativeAPI
  };
  
  Object.defineProperties(index, {
    'isFullscreen': {
      'get': () => getIsScreenFull()
    },
    'element': {
      'enumerable': true,
      'get': () => document[nativeAPI.fullscreenElement] ?? undefined
    },
    'isEnabled': {
      'enumerable': true,
      // Coerce to boolean in case of old WebKit.
      'get': () => Boolean(document[nativeAPI.fullscreenEnabled])
    }
  });
  
  export default index;
  
  