
import {set, get} from 'lodash';
import {WEBSOCKET_CODE, createMessage} from './message';
import {VimcallSocket} from './vimcall';
import {DtermSocket} from './dterm';
import {user, token} from '../user';

let promise = null;
let ideSocket = null;

export const initIdeSocket = () =>
{
  if(!token)
  {
    return Promise.resolve();
  }
  if(promise)
  {
    return promise;
  }
  promise = new Promise((resolve, reject) =>
  {
    const vimcallSocket = new VimcallSocket();
    set(window, 'top.codigger.network.gobalVimcall', vimcallSocket);
    ideSocket = vimcallSocket;
    vimcallSocket.initSocket(user).then((socket) =>
    {
      resolve(socket);
    });
  });
  return promise;
};

export const closeIdeSocket = () =>
{
  try
  {
    ideSocket?.instance?.close();
  }
  catch (error)
  {
    console.error('closeIdeSocket', 'error');
  }
  ideSocket = null;
  promise = null;
};

