import alertify from 'alertifyjs';
import { logout } from '../user';
import {closeIdeSocket} from '../network'
import * as i18n from '../i18n'

export const kickoff = () =>
{
  const content = (
    <div class="cus-body">
      <div class="cus-header">
        <img class="icon" src='/coreImages/alert-warning-icon.png' />
        <p>{i18n.t('alert.kickoff.title')}</p>
        {/* <img class="close" src='/coreImages/alert-close.png' /> */}
      </div>
      <div class="cus-content">
        {i18n.t('alert.kickoff.content')}
      </div>
      <div class="cus-footer">
        <div class="btn" onclick={() =>
        {
          logout();
          alert.destroy();
        }}>
          {i18n.t('alert.kickoff.ok')}
        </div>
      </div>
    </div>);
  closeIdeSocket();
  alertify.defaults.theme.ok = 'btn btn-primary';
  const alert = alertify.alert(content)
  .setting({
    'frameless':true,
    'transitionOff':true,
    'movable':false,
    closable:false,
    padding:false
  });
}