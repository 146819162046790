import en from './en.json';
import zh from './zh.json';
import { get } from 'lodash';
import {createUuid} from '../utils';

const languages = ['en', 'zh'];

const defaultLanguage = languages[0];

let language = localStorage.getItem('CodiggerLanguage') || defaultLanguage;

const callbacks = new Map();

const getJson = () =>
{
  return language === 'zh' ? zh : en;
}

const t = (key, defaultValue) =>
{
  return get(getJson(), key, defaultValue);
}

const setLanguage = (value) =>
{
  return new Promise((resolve, reject) =>
  {
    if(languages.includes(value))
    {
      localStorage.setItem('CodiggerLanguage', value);
      language = value;
      callbacks.forEach((val, key) =>
      {
        val && val(value);
      })
      resolve(true);
      return;
    }
    reject(false);
  })
};

const getLanguage = () =>
{
  return localStorage.getItem('CodiggerLanguage') || defaultLanguage;
};

const addLanguageListener = (callback, imm) =>
{
  if(imm)
  {
    callback && callback(getLanguage());
  }
  const key = createUuid();
  callbacks.set(key, callback);
  return key;
}

const removeLanguageListener = (key) =>
{
  return callbacks.delete(key);
}

export {
  t,
  setLanguage,
  getLanguage,
  removeLanguageListener,
  addLanguageListener
}

// export const initLanguage = () =>
// {
//   return Promise.resolve();
// }

