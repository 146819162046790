export const eventname = 'codigger-broadcast';

// 白名单列表
export const hostWhiteList = [
  'onecloud.cn',
  'codigger.com'
];

// 通信类型
export const channelType = {
  'postMessage': 1, // 适用非同源
  'broadcastChannel': 2, // 同源通信优先使用
  'localstorage': 3 // 同样通讯降级使用
};
