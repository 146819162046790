import './assets/css/reset.css';
import taskChain from './utils/taskChain';
import {initIdeSocket} from './network/index';
import { initRouter } from './router';
import { initUser } from './user';

try
{
  const isIframe = window.top !== window.self;
  if(!window?.top?.codigger)
  {
    // The order is important
    taskChain.push([initUser]);
    if(!isIframe && window.initIframe !== false)
    {
      taskChain.push(initRouter);
    }
    taskChain.push(initIdeSocket);
    taskChain.startExecute();
  }
}
catch (error)
{
  console.log('core error', error);
}

