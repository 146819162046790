import {find} from 'lodash';
import {getInstalledList} from './index';


export const checkInstallByModuleName = (workstation_id, moduleName) =>
{
  return new Promise((resolve, reject) =>
  {
    getInstalledList(workstation_id).then((list) =>
    {
      resolve(find(list, {moduleName}));
    })
    .catch((e) =>
    {
      reject(e);
    })
  });
}

export default {
  checkInstallByModuleName
}