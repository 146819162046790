import fullscreen from './fullScreen';

/*
*  自适应屏幕分辨率
*/
export const setScreenResolution = () =>
{
  const element = document.getElementById('codigger-main');
  const ratio1 = 16 / 9;
  const ratio2 = 16 / 10;
  const middleRatio = (ratio1 + ratio2) / 2;
  let ratioFlag = Math.max(screen.width, window.outerWidth) / Math.max(screen.height, window.outerHeight) > middleRatio;
  const h1 = 1080;
  const h2 = 1200;
  const width = 1920;
  let height = ratioFlag ? h1 : h2;
  // let height = 1920 * Math.max(screen.height, window.outerHeight) / Math.max(screen.width, window.outerWidth);
  const odlBodyOverflow = window.getComputedStyle(document.body).overflow;

  const initSize = () =>
  {
    if (!document.contains(element))
    {
      window.removeEventListener('resize', initSize);
      document.body.style.overflow = odlBodyOverflow;
      document.body.style.overscrollBehavior = '';
      return;
    }
    const screenWidth = Math.max(screen.width, window.outerWidth);
    const screenHeight = Math.max(screen.height, window.outerHeight);
    element.style.display = 'block';
    document.body.style.overscrollBehavior = 'none';

    console.log('----', window.innerWidth, window.innerHeight, window.outerWidth, window.outerHeight, screen.width, screen.height);
    if (fullscreen.isFullscreen)
    {
      console.log('is full screen');
      let h = window.innerHeight;
      document.body.style.overflow = 'hidden';
      element.style.width = width + 'px';
      console.log(h, screenWidth, height, width);
      console.log(Math.abs(h / screenWidth - height / width));
      if (Math.abs(h / screenWidth - height / width) < 0.001)
      {
        console.log('not change');
        element.style.height = height + 'px';
      }
      else
      {
        console.log('need change');
        element.style.height = (h / screenHeight * height) + 'px';
      }
      element.style.transformOrigin = '0 0';
      element.style.position = 'absolute';
      element.style.top = '50%';
      element.style.left = '50%';
      // const scale = Math.min(screenWidth / width, screenHeight / height);
      const scale = screenWidth / width;
      element.style.transform = `scale(${scale}) translate(-50%, -50%)`;
      element.style.marginRight = 0;
      element.style.marginTop = 0;
    }
    else
    {
      console.log('not full screen');
      document.body.style.overflow = 'auto';
      element.style.width = width + 'px';
      element.style.height = height + 'px';
      element.style.transformOrigin = '0 100%';
      element.style.position = 'static';
      element.style.top = '0';
      element.style.left = '0';
      // const scale = Math.min(screenWidth / width, screenHeight / height);
      const scale = screenWidth / width;
      element.style.transform = `scale(${scale})`;
      element.style.marginRight = `${screenWidth - width}px`;
      element.style.marginTop = `${-height * (1 - screenWidth / width)}px`;
    }
  };
  
  window.addEventListener('resize', initSize);
  initSize();

  const observer = new MutationObserver((mutatioinList) =>
  {
    for (let m of mutatioinList)
    {
      if (m.type === 'childList')
      {
        for (let node of m.removedNodes)
        {
          if (element === node)
          {
            window.removeEventListener('resize', initSize);
            document.body.style.overflow = odlBodyOverflow;
            document.body.style.overscrollBehavior = '';
            observer.disconnect();
          }
        }
      }
    }
  });

  observer.observe(element.parentNode, {
    childList: true
  });
}