/*
 * @Description:
 * @Author: laojunpeng
 * @Date: 2022-09-28 16:53:31
 * @LastEditTime: 2022-09-29 10:59:37
 * @LastEditors: laojunpeng
 * @FilePath: /src/CodiggerWeb/http/index.js
 *
 * This software is the confidential and proprietary information of
 * OneCloud Co., Ltd. ('Confidential Information'). You shall not
 * disclose such Confidential Information and shall use it only in
 * accordance with the terms of the license agreement you entered
 * into with OneCloud.
 */

import HttpClient from './HttpClient';

export default new HttpClient(
  {
    // 可配置项，详见CodiggerWeb/api/utils的createAxios
  }
);