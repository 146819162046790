export const injectFont = (targetWindow) =>
{
  const code = `
    @font-face {
      font-family: codiggerfont;
      src: url('${require('./JetBrainsMono-Regular.ttf')}');
      font-display:swap;
    }
    html,body{
      font-family: codiggerfont, PingFangSC-Regular, Micrsoft YaHei, WenQuanYi Micro Hei, Source Han Sans;

    }
  `;
  try
  {
    const style = targetWindow.document.createElement('style');
    style.type = 'text/css';
    style.rel = 'stylesheet';
    try
    {
      style.appendChild(document.createTextNode(code));
    }
    catch (error)
    {
      style.styleSheet.cssText = code;
    }
    const head = targetWindow.document.getElementsByTagName('head')[0];
    head.appendChild(style);
  }
  catch (error)
  {
    console.warn('injectFont', error);
  }
};