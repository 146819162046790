/*
 * @Description: api接口
 * @Author: laojunpeng
 * @Date: 2022-09-29 09:33:25
 * @LastEditTime: 2024-07-25 11:41:40

 * @LastEditors: laibin
 * @FilePath: /src/CodiggerWeb/api/index.js
 * OneCloud Co., Ltd. ('Confidential Information'). You shall not
 * disclose such Confidential Information and shall use it only in
 * into with OneCloud.
 */


import httpClient from './http';
import businessApi from './business';

export const PROJECT_STATUS = {
  'CLOSE':0,
  'LIST':1,
  'OPEN':2,
  'ACTIVE':3,
  'LIST_OR_OPEN':4,
  'OPEN_OR_ACTIVE':5,
  'LIST_OR_OPEN_OR_ACTIVE':6,
  'LOCK':7
};

const setGlobalProjcts = (projectId, setting) =>
{
  let sets = window.$projectSettings || {};
  if(!sets.hasOwnProperty(projectId))
  {
    sets[projectId] = setting;
    window.$projectSettings = sets;
  }
};

export function projectParamsConversion (data)
{
  let result = [];
  if(data.length > 0)
  {
    let arr = [];
    data.forEach((item) =>
    {
      try
      {
        item.project_setting = JSON.parse(decodeURIComponent(item.user_to_project.project_setting));
      }
      catch (error)
      {
        // item.project_setting = {};
      }
      let transformData = {
        ...item,
        ...item.mudem,
        ...item.project_instance,
        ...item.user_to_project,
        'id':item.project_instance.id,
        'projectID':item.project_instance.id,
        'username':item.project_instance.username,
        'userID':item.user_to_project.user_id,
        'templateID':item.project_instance.template_id,
        'projectName':item.project_instance.name,
        'type':item.project_instance.type,
        'children':item.project_instance.children,
        'parent':item.project_instance.parent,
        'status':item.user_to_project.status,
        'createTime':item.project_instance.CreatedAt,
        'lastOpenTime':item.user_to_project.open_at,
        'gitUrl':item.project_instance.git_url,
        'workstationID':item?.workstation?.id,
        'setting':item.project_setting,
        'project_setting':item.project_setting,
        'dtermUrl':item.dterm_url,
        'agentId':item?.mudem?.device_id,
        'agent_id': item?.mudem?.device_id,
        'user':item.user,
        'workstationDesc':item?.workstation?.description,
        'tmp_access': {
          'status': item.project_instance.temporary_access_status,
          ...item.temporary_access
        },
        ...item.project_instance,
        'ext':item?.mudem?.platform
      };
      arr.push(transformData);
    });
    result = arr;
  }
  else if(data.length === 0)
  {
    result = [];
  }
  else
  {
    try
    {
      data.project_setting = JSON.parse(decodeURIComponent(data.user_to_project.project_setting));
    }
    catch (error)
    {
      // data.project_setting = {};
    }
    result = {
      ...data,
      ...data.mudem,
      ...data.project_instance,
      ...data.user_to_project,
      'id':data.project_instance.id,
      'projectID':data.project_instance.id,
      'username':data.project_instance.username,
      'userID':data.user_to_project.user_id,
      'templateID':data.project_instance.template_id,
      'projectName':data.project_instance.name,
      'type':data.project_instance.type,
      'children':data.project_instance.children,
      'parent':data.project_instance.parent,
      'status':data.user_to_project.status,
      'createTime':data.project_instance.CreatedAt,
      'lastOpenTime':data.user_to_project.open_at,
      'gitUrl':data.project_instance.git_url,
      'workstationID':data?.workstation?.id,
      'setting':data.project_setting,
      'project_setting':data.project_setting,
      'dtermUrl':data.dterm_url,
      'agentId':data?.mudem?.device_id,
      'agent_id': data?.mudem?.device_id,
      'user':data.user,
      'workstationDesc':data?.workstation?.description,
      'tmp_access': {
        'status': data.project_instance.temporary_access_status,
        ...data.temporary_access
      },
      ...data.project_instance,
      'ext':data?.mudem?.platform
    };
  }
  return result;
}
/**
 * @description: 创建项目
 * @param {number} templateId
 * @param {string} name
 * @param {number} workstationId
 * @param {object} extdata
 * @return {Promise} data
 * @author: laojunpeng
 */
export const apiCreateProject = async (data) =>
{
  const {'templateId':template_id, 'gitUrl':git_url, 'isNew':is_new, projectSetting, codeReviewProjectSetting, workstationId, name, type, ...rest} = data.data;
  let obj = {
    'data':{
      ...rest,
      template_id,
      git_url,
      name,
      'project_setting':projectSetting || codeReviewProjectSetting,
      is_new,
      type
    }
  };
  if(workstationId)
  {
    obj.data.workstation_id = workstationId;
  }
  const result = await httpClient.put({'url':'/web-api/gateway/v2/project', ...obj});
  if(result)
  {
    let {'project_instance':{id, share_setting, type}} = result;
    if(type === 'project')
    {
      setGlobalProjcts(id, JSON.parse(share_setting));
    }

  }
  return projectParamsConversion(result);
};

/**
 * @description: 删除项目
 * @param {number} projectId
 * @author: lingc
 */
export const apiDeleteProject = async (projectId) =>
{
  return httpClient.del(`/web-api/gateway/project/${projectId}`);
};

/**
 * @description: 获取项目配置信息
 * @param {*} projectId
 * @return {*}
 * @author: laojunpeng
 */
export const apiGetProjectSetting = async (projectId) =>
{
  let setting = await httpClient.get({'url':`/web-api/gateway/project/setting/${projectId}`});
  setGlobalProjcts(projectId, setting);
  return setting;
};

/**
 * @description: 获取项目配置信息
 * @param {*} projectId
 * @return {*}
 * @author: laojunpeng
 */
export const apiGetSettingById = async (projectId) =>
{
  return await httpClient.get({'url':'/web-api/gateway/workstation/project_setting', 'params':{'project_id': projectId}, 'oauth':true});
};

/**
 * @description: 获取项目配置信息
 * @param {*} id
 * @return {*}
 * @author: laojunpeng
 */
export const apiSetSettingById = async (id, data) =>
{
  return httpClient.post({'url':`/web-api/gateway/workstation/${id}/push_conf`, data});
};

/**
 * 设置项目配置信息
 * @param {number} projectId
 * @param data
 * @returns {*}
 */
export const apiSetProjectSetting = (projectId, data) =>
{
  return httpClient.post({'url':`/web-api/gateway/project/setting/${projectId}`, ...data});
};

/**
 * 获取开发机的信息
 * @param data 数据
 * @returns {Promise} data
 */
export const apiGetSystemInfo = (data) =>
{
  return httpClient.post({'url':'/web-api/gateway/dterm/rcmd', ...data});
};

/**
 * 关闭项目
 * @param projectId 项目ID
 * @returns {Promise} data
 */
export const apiCloseProject = (projectId) =>
{
  return httpClient.post({'url':`/web-api/gateway/project/close/${projectId}`});
};

/**
 * 从历史列表移到左边树
 * @param projectId 项目ID
 * @returns {Promise} data
 */
export const apiShowProject = async (projectId) =>
{
  const result = await httpClient.post({'url':`/web-api/gateway/v2/project/${projectId}/status`, 'data':{'status': PROJECT_STATUS.LIST}});
  return projectParamsConversion(result);
};

/**
 * 项目状态变为打开
 * @param projectId 项目ID
 * @returns {Promise} data
 */
export const apiOpenProject = async (projectId) =>
{
  const result = await httpClient.post({'url':`/web-api/gateway/v2/project/${projectId}/status`, 'data':{'status': PROJECT_STATUS.OPEN, 'open_at_changed':true}});
  return projectParamsConversion(result);
};

/**
 * 项目状态变为激活
 * @param projectId 项目ID
 * @returns {Promise} data
 */
export const apiActiveProject = async (projectId) =>
{
  // return httpClient.post({'url':`/api/project/${projectId}`, 'data':{'status': PROJECT_STATUS.ACTIVE}});
  // const result = await httpClient.post({'url':`/api/project/${projectId}`, 'data':{'status': PROJECT_STATUS.ACTIVE}});
  const result = await httpClient.post({'url':`/web-api/gateway/v2/project/${projectId}/status`, 'data':{'status': PROJECT_STATUS.ACTIVE}});
  return projectParamsConversion(result);
};

/**
 * 从历史列表移到左边树
 * @param projectId 项目ID
 * @returns {Promise} data
 */
export const apiRemoveProject = async (projectId) =>
{
  const result = await httpClient.post({'url':`/web-api/gateway/v2/project/${projectId}/status`, 'data':{'status': PROJECT_STATUS.CLOSE}});
  return projectParamsConversion(result);
};

/**
 * 锁定项目
 * @param projectId 项目ID
 * @returns {Promise} data
 */
export const apiLockProject = async (projectId) =>
{
  const result = await httpClient.post({'url':`/web-api/gateway/v2/project/${projectId}/status`, 'data':{'status': PROJECT_STATUS.LOCK}});
  return projectParamsConversion(result);
};

/**
 * 项目状态变为激活
 * @param projectId 项目ID
 * @returns {Promise} data
 */
export const apiUpdateProject = async (projectId, data) =>
{
  const result = await httpClient.post({'url':`/web-api/gateway/v2/project/${projectId}`, data});
  return projectParamsConversion(result);
};
/**
 * 获取打开项目历史记录列表
 * @param {*} params
 */
//  getProjectList
// import { api, store, util } from '@/CodiggerWeb';

export const apiGetProjectList = async (data) =>
{
  const result = await httpClient.get({'url':'/web-api/gateway/v2/projects', ...data});
  result && result.map((item) =>
  {
    if(item.project_instance.type === 'project')
    {
      setGlobalProjcts(item.project_instance.id, JSON.parse(item.user_to_project.project_setting));
    }
  });
  return projectParamsConversion(result);
};

/**
 * 获取打开项目历史记录列表
 * @param {*} params
 */
export const apiGetProject = async (projectId) =>
{
  const result = await httpClient.get({'url':`/web-api/gateway/v2/project/${projectId}`});
  return projectParamsConversion(result);
};

/**
 * 获取项目组列表
 * @param {*} params
 */
export const apiGetGroups = async (data) =>
{
  const result = await httpClient.get({'url':'/web-api/gateway/v2/project/groups', ...data});
  return projectParamsConversion(result);
};

/**
 * 新建项目组
 * @param {*} params
 */
export const apiCreateGroup = (data) =>
{
  return httpClient.post({'url':'/web-api/gateway/project/group/create', data});
};

/**
 * @description: 获取开发类项目templateId
 * @return {*}
 * @author: laojunpeng
 */
export const apiGetDevProjectType = () =>
{
  return httpClient.get({'url':process.env.VUE_APP_PUBLIC_PATH + '/json/pluginType.json'});
};

/**
 * @description: 获取开发类项目templateId
 * @return {*}
 * @author: laojunpeng
 */
export const apiGetDevProjectIcon = () =>
{
  return httpClient.get({'url':process.env.VUE_APP_PUBLIC_PATH + 'json/plugins.json'});
};

// /**
//  * @description: 移除项目
//  * @param {*} projectId
//  * @return {*}
//  * @author: laojunpeng
//  */
// export const apiRemoveProject = (projectId) =>
// {
//   return httpClient.post({'url': `/api/project/removeFromList/${projectId}`});
// };

/**
 * 创建bundle
 * @param data
 * @returns {*}
 */
export const apiCreateBundle = (data) =>
{
  return httpClient.post({'url': '/web-api/gateway/bundle/persmssion/create', data});
};

/**
 * 获取rtty链接
 * @param {*} params
 */
export const apiFetchrtty = (data) =>
{
  return httpClient.get({'url': `/web-api/gateway/fetchDterm/${data}`});
};

/**
 * 获取项目列表排序
 * @param params
 * @param data
 * @returns {*}
 */
export const getProjectListOrder = () =>
{
  return httpClient.get({'url': '/web-api/gateway/ideSetting/project_list_order'});
};

/**
* 设置项目列表排序
* @param params
* @param data
* @returns {*}
*/
export const setProjectListOrder = (data) =>
{
  return httpClient.post({'url': '/web-api/gateway/ideSetting/project_list_order', data});
};

/**
* 设置项目列表排序
* @param params
* @param data
* @returns {*}
*/
export const getMudemDetail = (id) =>
{
  return httpClient.get({'url': `/web-api/gateway/mudem/device_id/${id}`});
};


export const getEnvFileVersion = () =>
{
  return httpClient.get({'url': '/web-api/gateway/virtual_env/env_file/version'});
};


export const getTemplateList = (workstationId, needUtility) =>
{
  let params = {};

  workstationId && (params.workstationId = workstationId);
  needUtility && (params.needUtility = 1);
  return httpClient.get({'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/codigger/user/modules/getTemplateList', 'params': params, 'oauth':true});
};

export const getInstalledList = (workstationId) =>
{
  return httpClient.get({'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/codigger/user/modules/getInstalledList', 'params':{workstationId}, 'oauth':true});
};

export const addInstallRecord = (data) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/codigger/modules/addInstallRecord',
    'data': data,
    'oauth': true
  });
};

export const getUserList = (data) =>
{
  return httpClient.get(
    {
      'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/loginUser/getUserList',
      'params': data,
      'oauth':true
    });
};

export const projectEditorSet = (data) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_DATA_CENTER_URL + '/codigger/project/editor/set',
    'data': data,
    'oauth': true
  });
};

export const deleteEnvFile = (data) =>
{
  return httpClient.post({'url': '/web-api/gateway/virtual_env/env_file/delete', data});
};

export const importEnvFile = (workstationID, data) =>
{
  return httpClient.post({
    'url': `/web-api/gateway/workstation/${workstationID}/import_env_file`,
    data
  },
  {
    'requestOptions': {
      'isReturnNativeResponse': true
    }
  }
  );
};

export const apiRefreshProjectProfile = (data) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/codigger/project/profile/refresh',
    'data': data,
    'oauth': true
  });
};

export const apiUserModuleInstallList = () =>
{
  return httpClient.get({
    'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/moduleInstallList/details',
    'oauth': true
  });
};

export const notificationSave = (data) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/notification/save',
    'data': data,
    'oauth': true
  });
};

export const notificationList = (data = {'pageNum': 1, 'pageSize': 1000000 }) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/notification/getPage',
    'data': data,
    'oauth': true
  });
};

export const notificationUpdateStatus = (data) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/notification/updateStatus',
    'data': data,
    'oauth': true
  });
};

export const notificationClean = (data) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/notification/deleteRecord',
    'data': data,
    'oauth': true
  });
};

export const sendModuleOwnerNotice = (data) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/notification/sendModuleOwnerNotice',
    'data': data,
    'oauth': true
  });
};


export const setProjectDeclarative = (data) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_DATA_CENTER_URL + '/codigger/project/module/declarative',
    'data': data,
    'oauth': true
  });
};

//
export const getProjectDeclarative = (data) =>
{
  return httpClient.get({
    'url': process.env.VUE_APP_DATA_CENTER_URL + '/codigger/project/module/declarative',
    'params': data,
    'oauth': true
  });
};


export const getBaseProfileDeclarative = async (data) =>
{
  return httpClient.get({
    'url': process.env.VUE_APP_DATA_CENTER_URL + '/codigger/profile/base/module/declarative',
    'params': data,
    'oauth': true
  });
};

export const setBaseProfileDeclarative = async (data) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_DATA_CENTER_URL + '/codigger/profile/base/module/declarative',
    data,
    'oauth':true
  });
};

export const getTemplateProfileDeclarative = async (data) =>
{
  return httpClient.get({
    'url': process.env.VUE_APP_DATA_CENTER_URL + '/codigger/profile/template/module/declarative',
    'params': data,
    'oauth': true
  });
};

// 设置 templateProfile hooks 信息
export const setTemplateProfileDeclarative = async (data) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_DATA_CENTER_URL + '/codigger/profile/template/module/declarative',
    data,
    'oauth':true
  });
};
// 默认设置
export const getDeclarativeSetting = (data) =>
{
  return httpClient.get({
    'url': process.env.VUE_APP_DATA_CENTER_URL + '/codigger/module/declarative',
    'params': data,
    'oauth': true
  });
};


export const getUserDeclarative = async (data) =>
{
  return httpClient.get({
    'url': process.env.VUE_APP_DATA_CENTER_URL + '/codigger/user/setting/module/declarative',
    'params': data,
    'oauth': true
  });
};

export const setUserDeclarative = async (data) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_DATA_CENTER_URL + '/codigger/user/setting/module/declarative',
    data,
    'oauth':true
  });
};

export const setProjectLoggingGateway = async (projectId, data) =>
{
  return httpClient.post({
    'url': `/web-api/gateway/project/log_setting/${projectId}`,
    data
  });
};

export const setProjectLogging = async (data) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_DATA_CENTER_URL + '/codigger/project/logging',
    data,
    'oauth':true
  });
};

export const getProjectLogging = async (data) =>
{
  return httpClient.get({
    'url': process.env.VUE_APP_DATA_CENTER_URL + '/codigger/project/logging',
    'params':data,
    'oauth':true
  });
};

export const getModuleUpdateList = async (data) =>
{
  return httpClient.get({
    'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/moduleInstallList/need/update/modules',
    'params':data,
    'oauth':true
  });
};

export const saveDeployConfig = async (data) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/codigger/deployConfig/save',
    'data':data,
    'oauth':true
  });
};

export const getDeployConfig = async (data) =>
{
  return httpClient.get({
    'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/codigger/deployConfig/get',
    'params':data,
    'oauth':true
  });
};

export const setUserDeclarativeComment = async (data) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_DATA_CENTER_URL + '/codigger/user/setting/module/declarative/comment',
    data,
    'oauth':true
  });
};

export const getToolbarSetting = async (data) =>
{
  return httpClient.get({
    'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/codigger/user/toolbar/get',
    'params':data,
    'oauth':true
  });
};

export const setTplDeclarativeComment = async (data) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_DATA_CENTER_URL + '/codigger/profile/template/module/declarative/comment',
    data,
    'oauth':true
  });
};

export const saveToolbarSetting = async (data) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/user/setting',
    'data':data,
    'oauth':true
  });
};

export const setBaseDeclarativeComment = async (data) =>
{
  return httpClient.post({
    'url': process.env.VUE_APP_DATA_CENTER_URL + '/codigger/profile/base/module/declarative/comment',
    data,
    'oauth':true
  });
};
export const getPowerbabaeAccount = async (data) =>
{
  return httpClient.get({
    'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/codigger/useroauth/get/binded/user',
    'params':data,
    'oauth':true
  });
};
export const getPowerbabeUserInfo = async (data) =>
{
  return httpClient.get({
    'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/codigger/useroauth/get/oauth/user',
    'params':data,
    'oauth':true
  });
};
export const getToolbarByProject = async (data) =>
{
  return httpClient.get({
    'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/codigger/user/toolbar/getProjectModuleToolbar',
    'params':data,
    'oauth':true
  });
};

export const getToolbarHooks = async (data) =>
{
  return httpClient.get({
    'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/codigger/user/toolbar/getHooksModuleToolbar',
    'params':data,
    'oauth':true
  });
};

export const upgradeMudem = async (data) =>
{
  return httpClient.post({
    'timeout':60 * 60 * 1000,
    'url': '/web-api/gateway/mudem/upgrade',
    'data':data,
    'oauth':true
  });
};

/**
 * 安装
 * @param {*} params
 */
 export const apiInstallModule = async (data) =>
 {
   return httpClient.post({'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/codigger/modules/installByName', data, 'oauth':true, 'timeout': 10 * 60 * 1000});
 };
 
 /**
  * 安装
  * @param {*} params
  */
 export const apiInstallModuleById = async (data) =>
 {
   return httpClient.post({'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/codigger/modules/install', data, 'oauth':true, 'timeout': 10 * 60 * 1000});
 };

 /**
 * 获取gateway保存的用户信息
 * @param data
 */
export const bindWorkstationById = async (data) => httpClient.post({
  'url': '/web-api/gateway/workstation/all_in_one',
  data
});

/**
 * 获取用户的机器列表（包括别人分享的）
 * 如果只传入 用户ID，就返回这个用户的所有关联，包括自己的跟别人分享给用户的。
 * 如果只传入开发机ID，就返回跟这个开发机有关联的列表
 * @param data
 */
export const getMachineList = async (data) => httpClient.get({
  'url': '/web-api/gateway/workstation/user_to_workstation/list',
  'params':data
});

/**
 * 查找module信息
 * @param data
 */
export const searchModule = async (params) => httpClient.get({'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/codigger/modules/searchByWorkstationId', params, 'oauth':true});

/**
 * 查找Sense.ose display
 * @param data
 */
export const getDisplayJson = async (params) => httpClient.get({
  'url': process.env.VUE_APP_SENSE + '/api/module/declarative/display.json',
  params
});

/**
 * 查找ability信息
 * @param data
 */
export const searchModuleAbility = async (params) => httpClient.get({'url': process.env.VUE_APP_SYSTEM_KEEPING_URL + '/codigger/store/module/declarative/display.json', params, 'oauth':true});


export default {
  ...businessApi,
  getDisplayJson,
  searchModule,
  bindWorkstationById,
  apiInstallModuleById,
  apiInstallModule,
  upgradeMudem,
  getDeployConfig,
  saveDeployConfig,
  getModuleUpdateList,
  sendModuleOwnerNotice,
  setProjectLoggingGateway,
  setProjectLogging,
  getProjectLogging,
  getTemplateProfileDeclarative,
  setTemplateProfileDeclarative,
  getBaseProfileDeclarative,
  setBaseProfileDeclarative,
  setProjectDeclarative,
  getProjectDeclarative,
  getDeclarativeSetting,
  setUserDeclarativeComment,
  setTplDeclarativeComment,
  setBaseDeclarativeComment,
  apiCreateProject,
  apiDeleteProject,
  apiGetProjectSetting,
  apiSetProjectSetting,
  apiGetSettingById,
  apiSetSettingById,
  apiGetSystemInfo,
  apiCloseProject,
  apiOpenProject,
  apiGetProjectList,
  apiGetGroups,
  apiGetDevProjectType,
  apiRemoveProject,
  apiLockProject,
  apiShowProject,
  apiGetDevProjectIcon,
  apiGetProject,
  apiCreateBundle,
  apiFetchrtty,
  getProjectListOrder,
  setProjectListOrder,
  getEnvFileVersion,
  apiUpdateProject,
  getTemplateList,
  addInstallRecord,
  deleteEnvFile,
  importEnvFile,
  getInstalledList,
  getUserList,
  projectEditorSet,
  apiRefreshProjectProfile,
  notificationSave,
  notificationList,
  notificationUpdateStatus,
  getToolbarSetting,
  saveToolbarSetting,
  getToolbarByProject,
  getToolbarHooks,
  searchModuleAbility
};
