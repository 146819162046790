/*
 * @Author: laibin
 * @email: laisan8848@163.com
 * @Date: 2024-09-02 12:39:08
 * @LastEditors: laibin
 * @LastEditTime: 2024-09-02 13:34:16
 * @Description: file content
 */
import { searchModule } from '../api';
import { set } from 'lodash';

export const initDesktopPool = async () =>
{
  let { moduleVersion } = await searchModule({'moduleName': 'web-onecloud-desktop'});
  if(moduleVersion.indexOf('1.0.') !== -1)
  {
    moduleVersion = '1.1.0';
  }
  let url = `${process.env.VUE_APP_REMOTE_URL}/web/desktop.sys/${moduleVersion}/desktop-pool.js`;
  const fn = new Function(`return import("${url}")`);
  const module = await fn();
  module.initDesktop?.();
  set(window, 'top.DesktopPool', module)
}