export const projectSocketMap = new Map();

export const close = (id) =>
{
  const socket = projectSocketMap.get(id);
  if(socket)
  {
    console.trace('close', socket);
    try
    {
      socket?.instance?.close();
      projectSocketMap.delete(id);
    }
    catch (error)
    {
      console.error('socket close fail', error);
    }
  }
};

export const remove = (id) =>
{
  if(id)
  {
    return projectSocketMap.delete(id);
  }
  return false;
};

export const add = (id, target) =>
{
  const socket = projectSocketMap.get(id);
  if(socket)
  {
    close(id);
  }
  projectSocketMap.set(id, target);
};

export const closeAll = () =>
{
  projectSocketMap.forEach((target, id, map) =>
  {
    try
    {
      target?.instance?.close();
    }
    catch (error)
    {

    }
  });
  projectSocketMap.clear();
};