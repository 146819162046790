const DESKTOP_STARTUP_SETTING = 'desktop_startup_setting';
const localStorage = window.top?.localStorage;

export const getActiveDesktop = () =>
{
  let setting = localStorage?.getItem(DESKTOP_STARTUP_SETTING);
  try {
    setting = setting ? JSON.parse(setting) : {};
  } catch (error) {
    setting = {}
    console.error(DESKTOP_STARTUP_SETTING + ' parse error:', error);
  }
  return setting
}

export const setActiveDesktop = (data) =>
{
  if (!data) return;
  const value = typeof data === 'object' ? JSON.stringify(data) : data;
  localStorage?.setItem(DESKTOP_STARTUP_SETTING, value);
}