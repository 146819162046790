/*
 * @Author: laibin
 * @email: laisan8848@163.com
 * @Date: 2024-07-22 10:23:34
 * @LastEditors: laibin
 * @LastEditTime: 2024-07-26 16:57:36
 * @Description: file content
 */
import icon from './icon.png'
import logo from './logo.png'
import bg from './bg.png'
import * as i18n from '../../i18n';

export default function Tmplate({msg, title}) {
  return <>
    <div class="extenedurl-wrapper">
      <div class="extenedurl-header">
        <img src={logo} />
        Codigger - Extended URL
      </div>
      <div class="extenedurl-body">
        <div class="extenedurl-error-wrapper">
          <img src={icon} width="144" height="144" />
          <div>
            <div class="extenedurl-error-title">{i18n.t('extenedUrl.error')}</div>
            <div class="extenedurl-error-desc">{msg}</div>
          </div>
        </div>
        <div class="extenedurl-btn-wrapper">
          <a class="extenedurl-error-btn" href="/web/portal">{i18n.t('extenedUrl.toDesktop')}</a>
          {/* <a class="extenedurl-learn-btn" href="/web/desktop">{i18n.t('extenedUrl.learn')}</a> */}
        </div>
      </div>
      <div class="extenedurl-footer">
        Copyright Ⓒ 2024 Codigger. All Rights Reserved.
      </div>
    </div>
    <style type="text/css">{`
      .extenedurl-btn-wrapper {
        display: flex;
        align-items: center;
        gap: 16px;
      }
      .extenedurl-learn-btn {
        text-decoration: none;
        color: #FF8000;
      }
      .extenedurl-error-btn {
        padding: 0 20px;
        height: 40px;
        border-radius: 20px;
        background: linear-gradient(0deg, #FF8000 0%, #F84D00 100%);
        box-sizing: border-box;
        border: 1px solid rgba(255, 255, 255, 0.2758);
        line-height: 40px;
        cursor: pointer;
        font-size: 16px;
        font-weight: normal;
        text-decoration: none;
        color: #fff;
      }
      .extenedurl-error-title {
        font-size: 36px;
        font-weight: normal;
        line-height: 80px;
      }
      .extenedurl-error-desc {
        font-size: 18px;
        font-weight: normal;
        color: rgba(255, 255, 255, 0.6);
      }
      .extenedurl-error-wrapper {
        display: flex;
        gap: 16px;
      }
      .extenedurl-body {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 150px;
        gap: 50px;
      }
      .extenedurl-wrapper {
        background: url(${bg});
        background-size: 100% 100%;
        height: 100vh;
        background-repeat: no-repeat;
        padding: 20px;
        color: #FFFFFF;
        display: grid;
        grid-template-rows: max-content 1fr 80px;
      }
      .extenedurl-header {
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: normal;
        gap: 10px;
      }
      .extenedurl-footer {
        text-align: center;
        font-size: 12px;
        font-weight: normal;
        color: rgba(255, 255, 255, 0.4002);
      }
    `}
    </style>
  </>
}