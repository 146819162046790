import { hostWhiteList } from './const';

let topId = 0;
/**
 * 生成唯一的 callbackName
 */
export function unique ()
{
  return `codiggerBroadcast_cb_${new Date().getTime() + topId++}`;
}

/**
 * logger
 * @param  {...any} arg
 */
export function debug (...arg)
{
  if(location.search.includes('debug=1'))
  {
    // eslint-disable-next-line no-console
    console.log('[codiggerBroadcast sdk debug log]', ...arg);
  }
}

/**
 * 白名单列表
 * @param {string}} urlOrigin 域名
 * @returns {boolean}
 */
export function inWhiteList (urlOrigin = '')
{
  if (typeof urlOrigin !== 'string') return false;
  let hosts = hostWhiteList.join('|');
  let reg = new RegExp(`^https?://[-0-9a-zA-Z.]*.*(${hosts})$`);
  const localhostReg = /^https?:\/\/localhost(:\d+)?$/;
  return reg.test(urlOrigin) || localhostReg.test(urlOrigin);
}

/**
 * 获取本地数据
 * @param {string} key localstorage key
 * @returns {any}
 */
export function getLocalstorageData (key)
{
  const value = window.localStorage.getItem(key);
  try
  {
    return JSON.parse(value);
  }
  catch (e)
  {
    debug(e);
  }
  return value;
}
