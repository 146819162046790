/* eslint-disable new-cap */
import { eventname } from './const';
import { inWhiteList, debug, unique, getLocalstorageData } from './utils';

class codiggerBroadcast
{
  constructor (child)
  {
    this.targetWin = child || window;
    this.singleton = null;
    this.handlers = {};
    this.isBroadcastChannel = typeof window.BroadcastChannel !== 'undefined';
    this.messageId = 1;
    this.scope = unique();

    this.init();
  }

  // 处理数据
  handleData (event)
  {
    // 判断origin是否在白名单
    if (!inWhiteList(event.origin)) return;

    // 检查数据
    if (!event.data) return;

    debug(this.scope, event.data);

    // 处理消息
    let { type, payload } = event.data;
    if (type === eventname)
    {
      const callbackMap = this.handlers[payload?.event];
      if (callbackMap?.size > 0)
      {
        Array.from(callbackMap).forEach((v) =>
        {
          if (typeof v[1] === 'function')
          {
            v[1](payload.data);
          }
        });
      }
    }
  }

  // 卸载监听
  unInit ()
  {
    this.singleton.removeEventListener('message', this.callback);
  }

  // 初始化
  init ()
  {
    if (this.isBroadcastChannel)
    {
      this.singleton = new BroadcastChannel(eventname);
      this.callback = this.handleData.bind(this);
      this.singleton.addEventListener('message', this.callback);
    }
    else
    {
      this.singleton = this.targetWin;
      this.callback = (e) =>
      {
        if (e.key.includes(eventname) && e.newValue)
        {
          try
          {
            const json = JSON.parse(e.newValue);
            this.handleData({
              ...json,
              'origin': this.targetWin.origin
            });
          }
          catch (err)
          {
            debug(err);
          }
        }
      };
      this.singleton.addEventListener('storage', this.callback);
    }
  }

  // 注册监听
  on (event, callback)
  {
    if (!this.handlers[event])
    {
      this.handlers[event] = new Map();
    }

    const eventKey = unique();

    this.handlers[event].set(eventKey, callback);

    return () =>
    {
      this.handlers[event].delete(eventKey);
    };
  }

  /**
   * 发送自定义事件
   * @param {any} event 事件名
   * @param {any} data 数据
   * @param {number} timeout 超时时间
   */
  emit (event, data, timeout = 0)
  {
    if (typeof data === 'function') return;
    if (this.isBroadcastChannel)
    {
      this.singleton?.postMessage({
        'type': eventname,
        'payload': {
          event,
          data
        }
      }, '*');
    }
    else
    {
      const key = `${eventname}-${event}`;
      const json = getLocalstorageData(key);
      this.messageId = json?.id ?? this.messageId;
      if (this.messageId === Number.MAX_SAFE_INTEGER)
      {
        this.messageId = 1;
      }
      else
      {
        this.messageId += 1;
      }
      this.targetWin.localStorage.setItem(key, JSON.stringify({
        'data': {
          'type': eventname,
          'payload': {
            event,
            data
          }
        },
        'id': this.messageId
      }));
      if (typeof Number(timeout) === 'number')
      {
        setTimeout(() =>
        {
          this.targetWin.localStorage.removeItem(key);
        }, timeout);
      }
    }
  }

  /**
   * 异步发送自定义事件
   * @param {any} event 事件名
   * @param {any} data 数据
   * @param {string} bizReadyName 接收通知的name
   * @param {any} query 目标query
   */
  asyncEmit (event, data, bizReadyName)
  {
    if (bizReadyName)
    {
      if (this.isBroadcastChannel)
      {
        const removeListen = this.on(bizReadyName, () =>
        {
          removeListen();
          this.emit(event, data);
        });
      }
      else
      {
        const removeListen = this.on(bizReadyName, () =>
        {
          removeListen();
          const key = `${eventname}-${bizReadyName}`;
          window.localStorage.removeItem(key);
          this.emit(event, data);
        });
      }
    }
  }
}

// let singleton = window.CodiggerBroadcast;

// /**
//  * 优选broadcastChannel通信,不支持降级为localstorage
//  * iframe的postMessage支持非同源通讯,但是使用起来不是很方便
//  */
// if (!singleton)
// {
//   window.CodiggerBroadcast = singleton = codiggerBroadcast;
// }
// todo 心跳

export default codiggerBroadcast;
