import { createUuid } from '../utils/index';
import {WEBSOCKET_CODE, createMessage, generateMessage} from './message';
import {get, find, isNil} from 'lodash';
import {user} from '../user';
import {kickoff} from '../alert';
import * as manager from './manager';

/**
 * @class
 * @classdesc aaa
 */
export class VimcallSocket
{
  constructor ()
  {
    /** socket status */
    this.status = 'start';
    /** init status */
    this.promise = null;
    /** socket instance */
    this.instance = null;
    /** src mudemId */
    this.broswerId = null;
    /** dst mudemId "1":global */
    this.mudemId = '1';
    /** dst peerId */
    this.peerId = '1';
    /** message promise pool */
    this.promisePool = new Map();
    /** timeout */
    this.timeout = 20 * 1000;
    /** connectionId */
    this.connectionId = null;
    /** listeners */
    this.messageListeners = new Map();

    this.dataListeners = new Map();
  }
  /**
   *
   * @param {Object} socket
   * @param {string} socket.username
   * @param {number} socket.projectId
   * @param {string} socket.url
   * @param {Object} socket.handshake
   * @param {number} socket.connectionId
   * @param {string} socket.mudemId
   * @param {string} socket.type
   * @returns {Promise} promise
   */
  initSocket ({username = user?.username, projectId, url, handshake, connectionId, mudemId, deviceId, type = 'ide', putPeers = false})
  {
    if(this.promise)
    {
      return this.promise;
    }
    const myConnectionId = connectionId || createUuid();
    // close same socket
    manager.close(myConnectionId);
    const socket = this.createSocket({username, projectId, url, 'connectionId':myConnectionId, deviceId});
    // there is no projectId for global socket
    if(projectId)
    {
      manager.add(myConnectionId, this);
    }
    this.projectId = projectId;
    this.connectionId = myConnectionId;
    this.mudemId = mudemId;
    // custom handshake
    const myHandshake = Object.assign({
      'connect_id':myConnectionId,
      username
    }, handshake);
    if(projectId)
    {
      myHandshake.project_id = projectId;
    }
    // init
    this.promise = new Promise((resolve, reject) =>
    {
      // send handshake when open
      socket.addEventListener('open', () =>
      {
        socket.send(JSON.stringify({
          type,
          'data': myHandshake
        }));
      });
      socket.addEventListener('message', (e) =>
      {
        const code = this.getMessageByKey(e, 'ah.code');
        // handshake
        switch (code)
        {
            case WEBSOCKET_CODE.START_FEEDBACK:
              this.broswerId = this.getMessageByKey(e, 'th.dst.mudemId');
              this.status = 'ready';
              if(!putPeers)
              {
                resolve(this);
              }
              break;
            case WEBSOCKET_CODE.PUT_PEERS:
              this.status = 'ready';
              if(putPeers)
              {
                resolve(this);
              }
              break;
            default:
              if(!isNil(code))
              {
                this.dispatch(e);
              }
              break;
        }
        const key = this.getMessageByKey(e, 'ah.mid');
        if (key && this.promisePool.has(key))
        {
          const data = this.getMessageByKey(e, 'ah');
          data.content = data.data;
          data.projectId = this.projectId;
          this.promisePool.get(key).resolve(data);
          this.promisePool.delete(key);
        }
      });
      this.initMessageListener();
    });
    return this.promise;
  }

  /**
   *
   * @param {Object} param
   * @param {number} param.projectId
   * @param {string} param.url
   * @param {string} param.username
   * @param {number} param.connectionId
   * @returns {Object} socket instance
   */
  createSocket ({projectId, url, username = user.username, connectionId, deviceId})
  {
    const vimSign = `${username}:${deviceId || projectId || connectionId}`;
    const hosts = ['codigger.onecloud.cn', 'trial.codigger.com', 'codigger.com', 'newcodigger.onecloud.cn'];
    const wsUrl = find(hosts, (o) =>
    {
      return location.host.indexOf(o) > -1;
    }) || hosts[0];
    const baseUrl = projectId ? url : `wss://${wsUrl}/web-api/gateway/ws/ide`;
    const websocketUrl = `${baseUrl}?VimSig=${vimSign}`;
    this.instance = new WebSocket(websocketUrl, 'vim');
    if(!projectId)
    {
      window.SIDE_WEBSOCKET = this;
    }
    return this.instance;
  }

  /**
   *
   * @param {*} command respond to dst
   * @param {*} receive dst message
   */
  respondMessage ({command, receive})
  {
    if(!receive || !command)
    {
      return Promise.reject('respondMessage: no message or no receive');
    }
    const dst = this.getMessageByKey(receive, 'dst');
    return this.dispatchMessage({command, ...dst});
  }

  dispatchMessage ({command, mudemId, projectId, peerId, mid})
  {
    const message = generateMessage({
      'dstProjectId': projectId || '1',
      'dstMudemId': mudemId,
      'dstPeerId': peerId || '0',
      'srcProjectId': this.projectId || '1',
      'srcMudemId':this.broswerId,
      'srcPeerId':'browser',
      'connectionId':this.connectionId,
      command,
      'code':WEBSOCKET_CODE.OK,
      mid
    });
    this.instance.send(JSON.stringify(message));
  }

  /**
   *
   * @function
   * @param {*} param
   * @returns {promise} response promise
   */
  sendMessage ({
    command,
    cusAgentId,
    code = WEBSOCKET_CODE.OK,
    isPromise = true,
    cooperId = '',
    timeout
  })
  {
    const {message, mid} = createMessage({
      'projectId':this.projectId,
      'cusAgentId': cusAgentId || this.mudemId,
      'broswerId':this.broswerId,
      'peerId':this.peerId,
      'connectionId':this.connectionId,
      command,
      code,
      cooperId
    });
    if (isPromise)
    {
      return new Promise((resolve, reject) =>
      {
        const promiseId = mid;
        this.promisePool.set(promiseId, {
          resolve,
          reject,
          'jsonData':message
        });
        this.instance.send(JSON.stringify(message));
        // timeout
        setTimeout(() =>
        {
          if (this.promisePool.get(promiseId)?.reject)
          {
            this.promisePool.get(promiseId)?.reject(message);
            this.promisePool.delete(promiseId);
          }
        }, timeout || this.timeout);
      });
    }
    this.instance.send(JSON.stringify(message));
  }

  /**
   * @function initMessageListener register gobal event
   */
  initMessageListener ()
  {
    this.instance.onclose = (e) =>
    {
      console.log('closewebsocket', e);
    };
    this.instance.onerror = (e) =>
    {
      console.log('closewebsocketerror', e);
    };
    this.instance.addEventListener('message', (e) =>
    {
      const code = this.getMessageByKey(e, 'ah.code');
      // kickoff
      if(code === WEBSOCKET_CODE.LOGOFF)
      {
        kickoff();
        e.preventDefault();
      }
    });
  }
  /**
   * @function dispatch dispatch message to callback
   * @param {*} message
   */
  dispatch (message)
  {
    const callBacks = this.messageListeners;
    callBacks.forEach((callBack) =>
    {
      callBack && callBack(message);
    });
    this.dataListeners.forEach((dataCallback) =>
    {
      const data = this.getMessageByKey(message, 'ah.data');
      if(data)
      {
        dataCallback && dataCallback(data);
      }
    });
  }
  addDataListener (callback)
  {
    if (!callback)
    {
      return;
    }
    const key = Symbol();
    this.dataListeners.set(key, callback);
    return key;
  }
  /**
   *
   * @param {function} callback
   * @returns {Symbol} key for remove listener
   */
  messageListener (callback)
  {
    if (!callback)
    {
      return;
    }
    const key = Symbol();
    this.messageListeners.set(key, callback);
    return key;
  }
  /**
   * @function getMessageByKey analyze message content
   * @param {Object} message
   * @param {string} key
   * @returns value
   */
  getMessageByKey (e, key)
  {
    try
    {
      const webSocketMessage = JSON.parse(e?.data);
      return get(webSocketMessage, key);
    }
    catch (error)
    {
      return null;
    }
  }
  /**
   *
   * @returns {string} status
   */
  getSocketStatus ()
  {
    return this.status;
  }
  /**
   *
   * @param {Symbol} key
   * @returns {boolean} result
   */
  removeMessageListener (key)
  {
    if(key)
    {
      return this.messageListeners.delete(key);
    }
    return this.messageListeners.clear();
  }

  /**
   *
   * @param {Symbol} key
   * @returns {boolean} result
   */
  removeDataListener (key)
  {
    if(key)
    {
      return this.dataListeners.delete(key);
    }
    return this.dataListeners.clear();
  }

  /**
   * @function close closeSocket
   * @returns {boolean} result
   */
  close ()
  {
    if(this.projectId)
    {
      manager.remove(this.projectId);
    }
    return this.instance?.close();
  }
}
