import closeBox from './icon/close-box.png';
import closeIcon from './icon/close-icon.png';
import detailIcon from './icon/detail-icon.png';

import infoIcon from './icon/info-icon.png';
import successIcon from './icon/success-icon.png';
import errorIcon from './icon/error-icon.png';
import alertIcon from './icon/alert-icon.png';

export const templateIcon = {
  closeBox,
  closeIcon,
  detailIcon
};

export const Icon = {
  'info': infoIcon,
  'success': successIcon,
  'error': errorIcon,
  'alert': alertIcon
};