import {isArray} from 'lodash';

const chain = [];

const push = (task) =>
{
  if(isArray(task))
  {
    for(let i = 0; i < task.length; i++)
    {
      push(task[i]);
    }
    return;
  }
  chain.push(task);
};

const startExecute = () =>
{
  return new Promise((resolve, reject) =>
  {
    const execute = (result) =>
    {
      const task = chain.shift();
      if(!task)
      {
        resolve();
        return;
      }
      task.apply(this, result).then((...rest) =>
      {
        execute(rest);
      }).catch((e) =>
      {
        console.log('rest error', e);
        reject();
      })
    }
    execute();
  })
}

export default {
  chain,
  push,
  startExecute
}